<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4 create-new">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: AnnouncementManagePath.name}">
					<em class="fa fa-backward"></em> Manage Announcement
				</router-link>
			</b-col>
			<b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Create New Announcement
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Title *">
										<b-form-input placeholder="input title" id="title" v-model="form.title" type="text" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Content *">
										<b-form-textarea
											id="content"
											v-model="form.content"
											placeholder="input content"
											rows="6"
											max-rows="12"
											required
										>
										</b-form-textarea>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6">
									<b-form-group label="Publisher">
										<multiselect v-model="form.publisher" label="name" track-by="id" placeholder="Publisher" open-direction="bottom" :options="publisherSearchOptions" :searchable="true" :loading="isPublisherSearch" :close-on-select="true" :clear-on-select="false" :preserve-search="false" :preselect-first="false" :multiple="false" :taggable="false" @search-change="publisherFind" required></multiselect>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group>
										<b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">
											Is Active
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-button variant="primary" type="submit">create</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AnnouncementManagePath } from "../../router/announcements";

export default {
	name: "new-announcement",
	data() {
		return {
			debounce: null,
			AnnouncementManagePath,
			form: {
				title: null,
				content: null,
				publisher: null,
				is_active: false
			},
			isPublisherSearch: false,
			publisherSearchOptions: [],
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.announcement.isError,
			isLoading: (state) => state.announcement.isLoading,
			errorMessage: (state) => state.announcement.errorMessage,
			successMessage: (state) => state.announcement.successMessage,
			item: (state) => state.announcement.item
		})
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);

			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(AnnouncementManagePath);
		}
	},
	created() {},
	methods: {
		...mapActions("announcement", ["postAnnouncements"]),
		...mapActions("vendors", ["AutocompleteSearch"]),

		onSubmit(event) {
			event.preventDefault();
			const data = this.form;
			const payload = {
				title: data.title,
				content: data.content,
				publisher_id: data.publisher?.id ? data.publisher.id : 0,
				is_active: data.is_active ? true : false,
			};
			this.postAnnouncements(payload);
		},
		onReset() {},
		publisherFind(query) {
			if (!query) return;

			this.isPublisherSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.AutocompleteSearch({ q: query })
					.then((response) => {
						this.publisherSearchOptions = response.data.data.rows;
						this.isPublisherSearch = false;
					})
					.catch(() => {
						this.isPublisherSearch = false;
					});
			}, 1200);
		},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
	},
};
</script>
